import React from "react"
import HorizontalMenu from "../sidebar/horizontalmenu"
import SideBarMenu from "../sidebar/sidebar"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
// import Header from "./header"
import Header from "../../components/header/headermain"
import loadable from "@loadable/component"
import ExitIntent from "../../components/modal/exit-intent-email"
import Script from "react-inline-script"

// const Footer = loadable(() => import("../footer/footer"))
import Footer from "../footer/footer"
// const Widget = loadable(() => import("../widget"))

const MainLayout = ({ children, location, dataSrc }) => {
  return (
    <>
      <Helmet>
        {/* <Script
          type="text/javascript"
          async
          defer
          src="https://www.googletagmanager.com/gtag/js?id=AW-838287664"
        ></Script>
        <Script type="text/javascript" async defer>
          window.dataLayer = window.dataLayer || []; function gtag()
          {`window.dataLayer.push(arguments);`}ß gtag('js', new Date());
          gtag('config', 'AW-838287664/OrExCL29oJEBELCC3Y8D');
        </Script>*/}

        {/* 6/8/2024 */}

        {/* Global Site Tag (gtag.js) - Google Ads */}
        {/* <script async src={`https://www.googletagmanager.com/gtag/js?id=AW-838287664`}></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              // Global Site Tag configuration for Google Ads
              gtag('config', 'AW-838287664');

              // Google Ads Remarketing Tag configuration
              gtag('config', 'AW-838287664', {
                'send_page_view': false,
                'allow_ad_personalization_signals': true
              });

              // Google Ads Conversion ID
              gtag('config', 'AW-838287664/OrExCL29oJEBELCC3Y8D');
            `,
          }}
        /> */}
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        {/* <script async src={`https://www.googletagmanager.com/gtag/js?id=G-BG4XSGSXK2`}></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              
              gtag('config', 'G-BG4XSGSXK2');
            `,
          }}
        /> */}
        {/* Global Site Tag (gtag.js) for Google Analytics and Google Ads */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-BG4XSGSXK2`}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              // Google Analytics configuration
              gtag('config', 'G-BG4XSGSXK2');

              // Google Ads configuration (including remarketing and conversion tracking)
              gtag('config', 'G-BG4XSGSXK2');
              
              // Google Ads Remarketing Tag configuration
              gtag('config', 'G-BG4XSGSXK2', {
                'send_page_view': false,
                'allow_ad_personalization_signals': true
              });
              
              // Google Ads Conversion ID
              gtag('event', 'conversion', {
                'send_to': 'G-BG4XSGSXK2/OrExCL29oJEBELCC3Y8D'
              });
            `,
          }}
        />
        <meta
          name="google-site-verification"
          content="mbUtOKe-8Cb7R5fNdeIDXF9lT978nuAp4gNHKJOiU3E"
        />
      </Helmet>

      <Header dataSrc={dataSrc} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default MainLayout
