import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { LazyLoadComponent } from "react-lazy-load-image-component"
// import ScrollBtn from "../button/scroll-btn"
import useMediaQuery from "../../hooks/useMediaQuery"
import loadable from "@loadable/component"
import Swiper from "react-id-swiper"
import { Pagination, Navigation } from "swiper/js/swiper.esm"
import "./hero.css"

const HeroLogo = loadable(() => import("./hero-logos"))
const ScrollBtn = loadable(() => import("../button/scroll-btn"))

const SwiperHero = [
  {
    name: "firstSwiper",
    title: "Get More Out of Your Low-Code and No-Code Platforms. Faster.",
    description:
      "We build applications that operationalize AI by leveraging low-code automation, integration, and data orchestration.",
    label: "Learn More",
    url: "/our-approach",
    anchorLink: "/#it-consult",
  },
  {
    name: "secondSwiper",
    title: "Custom Construction Management Applications for Every Project",
    description:
      "Solve your construction project management challenges with low-code applications, custom fit for each job.",
    label: "Learn More",
    url: "/offline-application-development-construction-management/",
    anchorLink: "/#it-consult",
  },
  {
    name: "thirdSwiper",
    title: "Custom Retail Applications to Keep Customers Coming Back",
    description:
      "Connect to your ERP, Data Management, and streamlining your Fulfillment Processes with our low-code development solutions.",
    label: "Learn More",
    url: "/retail-automation",
    anchorLink: "/#it-consult",
  },
]

const Hero = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(relativePath: { eq: "new-hero.jpg" }) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1170
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  // const isDesktop = useMediaQuery("(min-width: 768px)")

  const params = {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 30,
    effect: "fade",
    breakpoints: {
      1024: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    containerClass: "swiper-container swiper-hero",
    nested: true,
    loop: "true",
    autoplay: {
      delay: 15000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  const styles = {
    fontFamily: "Futura,sans-serif",
    marginBottom: "1.5rem",
    fontWeight: 500,
  }

  return (
    <>
      <Swiper {...params}>
        {SwiperHero?.map((item, index) => (
          <div className={`hero-banner relative ${item?.name}`} key={index}>
            <div className="hero-overlay" />

            <div className="container">
              <div className="row pb-3">
                <div className="col-lg-7 col-xl-8 col-md-9">
                  <div className="hero-custom-content-home  review-content text-white">
                    <h1
                      className="page-title"
                      style={{
                        marginBottom: "0.5rem",
                        display: "inline",
                      }}
                    >
                      {item?.title}
                    </h1>
                    <p className="mb-0 mt-2">{item.description}</p>
                    {item?.url && (
                      <div className="hero-button">
                        <Link
                          to={`${item?.url}`}
                          className="btn btn-primary btn-arrow mr-2 mt-4 text-center text-capitalize py-3"
                          style={{ marginTop: "auto" }}
                        >
                          {item?.label}
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div
                  className="col-lg-12 col-md-12 pb-2 d-flex justify-content-end"
                  style={{ position: "relative" }}
                >
                  <HeroLogo />
                </div> */}
              </div>
            </div>

            <div className="px-5 swiper-scroll-down">
              <div className="arrow bounce" />
            </div>
            <div className="swiperOverlay"></div>
          </div>
        ))}
      </Swiper>
    </>
  )
}

export default Hero
